"use client";

import Link from "next/link";
import Badge from "../shared/Badge";
import capitalize from "lodash/capitalize";
import Button from "../shared/Button/button";
import ImageWithFallback from "../ImageWithFallback";
import SpecialPriceBadge from "./SpecialPriceBadge";
import useTrolleyUtility from "@/lib/hooks/useTrolleyUtility";
import {
  constants,
  formatProductCategoriesGA4,
  mutateProductInfo,
  routes,
  updateGA4PayloadToSelectItem,
  updateRecentlyViewed,
} from "@/lib/utils";
import { add_to_cart, select_item } from "@/lib/gtm";
import Cookies from "js-cookie";
import { useTranslation } from "@/i18n/client";
import { useTouchHandler } from "@/lib/hooks/useTouchHandler";

export default function ProductsCard({
  product = {},
  loop_index,
  item_list = "",
  imageLoader = {},
  
}) {
  const { t } = useTranslation(["common"]);
  const touchHandlers = useTouchHandler();

  const productInfo = mutateProductInfo(product);
  const selectedBranchStock = product?.branch_stock && product.branch_stock > 0;
  const productBranch = product?.branch || {};
  const handleRecentlyViewed = () => updateRecentlyViewed(productInfo?.sku);
  const {
    methods: { handleAddToTrolley },
  } = useTrolleyUtility();
  let ga4_payload = {
    currency: productInfo?.price_range?.minimum_price?.final_price?.currency,
    value: productInfo?.price_range?.minimum_price?.final_price?.value,
    items: [
      {
        item_id: productInfo?.sku,
        item_name: productInfo?.original_name,
        discount: productInfo?.price_range?.minimum_price?.discount?.amount_off,
        item_brand: productInfo?.first_brand?.title,
        price: productInfo?.price_range?.minimum_price?.final_price?.value,
        quantity: 1,
        index: loop_index,
        ...(Number(
          productInfo?.price_range?.minimum_price?.discount?.amount_off,
        ) &&
          !productInfo?.specialCoupon_applied && {
            coupon: Cookies.get("specialCoupon"),
          }),
        ...formatProductCategoriesGA4(productInfo?.extraVariable?.breadcrumbs),
      },
    ],
  };

  const addToCartEvent = () => {
    add_to_cart(ga4_payload);
  };
  const addToBasket = () => {
    handleAddToTrolley({
      product: {
        ...productInfo,
        soldInPairs: productInfo?.sold_in_pairs,
      },
      addToCartEvent: addToCartEvent,
    });
  };

  return (
    <div className="productBox">
      <div className="coverProductImage">
        <Link prefetch={false}
          href={`${routes.products}/${productInfo.url_key}`}
          onClick={handleRecentlyViewed}
        >
          <div
            onClick={() => {
              select_item(
                updateGA4PayloadToSelectItem(
                  ga4_payload,
                  productInfo?.extraVariable?.breadcrumbs,
                  item_list,
                ),
              );
            }}
          >
            <ImageWithFallback
              width={308}
              height={280}
              {...imageLoader}
              src={productInfo?.thumbnail?.url}
              type={constants.image_types.product}
              className="h-[128px] xs:h-[280px] max-h-full"
              alt={productInfo?.thumbnail?.label || "Product"}
            />
          </div>
        </Link>
        <SpecialPriceBadge
          showBadge={productInfo?.showDiscountedPrice}
          price={productInfo?.amountOff}
        />
      </div>
      <div className="p-[8px] xs:p-[10px] xs:pb-3 flex flex-col justify-between h-12/12">
        <Badge
          bestSeller={productInfo?.best_seller == 1}
          recommended={productInfo?.is_recommended == 1}
        />

        {/* Product Description */}
        <Link prefetch={false}
          href={`${routes.products}/${productInfo.url_key}`}
          onClick={handleRecentlyViewed}
          className="sm:h-[52px] xs:h-[48px] h-[40px] overflow-hidden overflow-ellipsis whitespace-pre-wrap flex flex-col"
        >
          <h2
            className="heading-18-b lg:max-w-12/12 xs:max-w-[90%] flex-1 h-auto line-clamp-2"
            onClick={() => {
              select_item(
                updateGA4PayloadToSelectItem(
                  ga4_payload,
                  productInfo?.extraVariable?.breadcrumbs,
                  item_list,
                ),
              );
            }}
          >
            {productInfo?.name || "Product"}
          </h2>
        </Link>
        {/* Product Description */}

        <div className="mt-auto">
          <div className="my-1 xs:my-10">
            <span
              className={`productPrice ${
                productInfo?.showStrikePrice ? "red" : ""
              }`}
            >
              {productInfo?.final_price}
            </span>
            {productInfo?.showStrikePrice && (
              <span className="oldPrice">{productInfo?.regular_price}</span>
            )}
          </div>
          <Button
            theme="add-to-basket-btn"
            icon="shopping"
            type="button"
            iconPosition="left"
            {...touchHandlers}
            onClick={addToBasket}
            disabled={productBranch?.branch_id && !selectedBranchStock}
          >
            {t("button.add_to_basket")}
          </Button>
        </div>
      </div>
    </div>
  );
}
