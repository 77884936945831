"use client";

import Link from "next/link";
import { memo } from "react";
import Image from "next/image";
import useScreenSize from "@/lib/hooks/useScreenSize";
import { getContentfulImgData } from "@/lib/services/contentful";
import CarRegistrationBox from "@/components/common/shared/CarRegistration";

const Banner = ({ banner, bannerImageForMobile }) => {
  const {
    data: { isSmallScreen },
  } = useScreenSize({ data: [] });

  const bannerImage = getContentfulImgData(banner?.fields?.image);
  const bannerImageMobile = getContentfulImgData(
    bannerImageForMobile?.fields?.image,
  );

  const bannerLink = banner?.fields?.link;
  const MobileBanner = (
    <>
      {!!bannerImageMobile.imageUrl && (
        <Image
          priority
          width={575}
          height={575}
          loading="eager"
          src={bannerImageMobile.imageUrl}
          className="w-12/12 xs:order-1 order-2 object-contain"
          alt={bannerImageMobile.title || bannerImageMobile.fileName}
        />
      )}
    </>
  );

  const BannerImage = (
    <>
      {!!bannerImage.imageUrl && (
        <Image
          priority
          width={1440}
          height={440}
          loading="eager"
          src={bannerImage.imageUrl}
          className="w-12/12 xs:order-1 order-2"
          alt={bannerImage.title || bannerImage.fileName}
        />
      )}
    </>
  );

  return (
    <section className="w-12/12 relative flex flex-col xs:pb-[65px] pb-24 max-w-[1440px] mx-auto z-[2]">
      <CarRegistrationBox />
      {bannerLink ? (
        <Link
          rel="preload"
          prefetch={false}
          href={bannerLink}
          className="banner-section"
        >
          {isSmallScreen ? MobileBanner : BannerImage}
        </Link>
      ) : isSmallScreen ? (
        MobileBanner
      ) : (
        BannerImage
      )}
    </section>
  );
};

export default memo(Banner);
