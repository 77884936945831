import(/* webpackMode: "eager" */ "/var/www/html/GSF-CARPARTS/FE/Nextjs/node_modules/next/dist/client/image-component.js");
import(/* webpackMode: "eager" */ "/var/www/html/GSF-CARPARTS/FE/Nextjs/node_modules/next/dist/client/link.js");
import(/* webpackMode: "eager" */ "/var/www/html/GSF-CARPARTS/FE/Nextjs/node_modules/react-toastify/dist/react-toastify.esm.mjs");
import(/* webpackMode: "eager" */ "/var/www/html/GSF-CARPARTS/FE/Nextjs/src/app/[locale]/(default-layout)/(home)/components/Banner/index.jsx");
import(/* webpackMode: "eager" */ "/var/www/html/GSF-CARPARTS/FE/Nextjs/src/app/[locale]/(default-layout)/(home)/components/Cart/index.jsx");
import(/* webpackMode: "eager" */ "/var/www/html/GSF-CARPARTS/FE/Nextjs/src/app/[locale]/(default-layout)/(home)/components/Category/index.jsx");
import(/* webpackMode: "eager" */ "/var/www/html/GSF-CARPARTS/FE/Nextjs/src/app/[locale]/(default-layout)/(home)/components/Deals/index.jsx");
import(/* webpackMode: "eager" */ "/var/www/html/GSF-CARPARTS/FE/Nextjs/src/app/[locale]/(default-layout)/(home)/components/Review/ReviewSlider.jsx");
import(/* webpackMode: "eager" */ "/var/www/html/GSF-CARPARTS/FE/Nextjs/src/app/[locale]/(default-layout)/(home)/components/Season/index.jsx");
import(/* webpackMode: "eager" */ "/var/www/html/GSF-CARPARTS/FE/Nextjs/src/app/[locale]/(default-layout)/(home)/components/ShopNowBanner/index.jsx");
import(/* webpackMode: "eager" */ "/var/www/html/GSF-CARPARTS/FE/Nextjs/src/assets/css/pages/home.css");
import(/* webpackMode: "eager" */ "/var/www/html/GSF-CARPARTS/FE/Nextjs/src/components/common/shared/CustomToaster/index.jsx");
import(/* webpackMode: "eager" */ "/var/www/html/GSF-CARPARTS/FE/Nextjs/src/components/common/WhyGsf/index.jsx");
import(/* webpackMode: "eager" */ "/var/www/html/GSF-CARPARTS/FE/Nextjs/src/components/common/zendesk/index.jsx");
import(/* webpackMode: "eager" */ "/var/www/html/GSF-CARPARTS/FE/Nextjs/src/components/misc/ErrorHandler.jsx")